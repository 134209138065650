<template>

<div class="login" :class="{'is-success': is.success}">

	<div class="login-box">

		<div class="login-title">Forgot password</div>

		<template v-if="is.success">

			<div class="login-text">Thank you, you will shortly receive an e-mail with password reset instructions if a matching account was found.</div>

		</template>

		<template v-if="!is.success">

			<div class="login-text">Enter your e-mail address and if a matching account exists we will send you a Reset Password e-mail.</div>

			<app-input-text :noLine="true" :disabled="is.loading" :stacked="true" v-model="model.email" placeholder="Enter e-mail address" maxlength="128" />

			<app-button text="Submit" class="login-button" v-on:click.native.stop="onSubmitClick" :disabled="!isValid" :loading="is.loading" />

		</template>

		<router-link :to="{ name: 'Login' }" class="login-link"><i class="fa fa-caret-left"></i> <span>back to login</span></router-link>

	</div>

</div>

</template>

<script>

export default {

	data: function() {

		return {
			is: {
				loading: false,
				success: false
			},
			reason: '',
			model: {
				email: ''
			}
		}

	},

	computed: {

		isValid: function() {

			return this.model.email
		
		}

	},

	methods: {

		onSubmitClick: function() {

			this.is.loading = true

			this.$api.post('login/forgot', this.model).then(function() {

				this.is.loading = false
				this.is.success = true

			}.bind(this), function() {

				this.is.loading = false

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.login {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	background-color: #1e3054;
	padding-left: 50%;
	display: flex;
	align-items: center;
	transition: background-color 100ms linear;
}

.login.is-success {
	background-color: #4faa80;
}

.login:before {
	content: '';
	position: absolute;
	left: 0px;
	top: 0px;
	bottom: 0px;
	width: 50%;
	background-image: url(https://api.bigbox.dev/files/uploads/62e2ed3b99bbe.jpg);
	background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #2f3a4a;
    filter: sepia(100%) hue-rotate(190deg) saturate(400%) brightness(50%);
}

.is-mobile .login {
	padding-left: 0%;
	display: block;
}

.is-mobile .login:before {
	width: 100%;
}

.login-box {
	width: 365px;
	border-radius: 0px 10px 10px 0px;
	padding: 40px;
}

.is-mobile .login-box {
	position: absolute;
	left: 0px;
	bottom: 0px;
	background-color: #1e3054;
	width: 100%;
	max-width: 100%;
	padding: 20px;
	border-radius: 0px;
}

.login-title {
	color: #fff;
	font-size: 24px;
	margin-bottom: 18px;
}

.login-text {
	color: #fff;
	font-size: 14px;
	line-height: 18px;
	margin-bottom: 20px;
}

.login-convention {
	display: flex;
	margin-bottom: 10px;
	align-items: center;
	background-color: #fff;
	border-radius: 10px;
}

.login-convention-logo {
	width: 64px;
	height: 64px;
	border: 5px solid #fff;
	border-radius: 10px;
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-image: url(https://static.wixstatic.com/media/c05de0_f353a33ed0ea482994f74f208189e142~mv2_d_1372_1474_s_2.gif);
}

.login-convention-text {
	color: #1e3054;
}

.login-convention-text-name {
	font-size: 20px;
	font-weight: 600;
}

.login-convention-text-date {
	font-size: 14px;
	opacity: 0.75;
	margin-top: 3px;
}

.login-button {
	width: 100%;
	margin-top: 20px;
}

.login-link {
	font-size: 12px;
	color: #fff;
	margin-top: 20px;
	letter-spacing: 1px;
	cursor: pointer;
	text-transform: lowercase;
	display: block;
	opacity: 0.75;
}

.login-link:hover {
	opacity: 1;
}

.login-link:hover span {
	text-decoration: underline;
}

</style>
